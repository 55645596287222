<template>
  <div>
    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>
          <div class="mb-1">
            {{ $t('indicacao.extrato.titleCardStatistics') }}
          </div>
          <h3
            v-if="resumo"
            class="mb-1 font-weight-bolder"
          >
            {{ formatCurrencyUSD(resumo.total, 2) }}
          </h3>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="6"
            md="2"
            class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside class="mt-1">
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    icon="BellIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body
                :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start ml-1' : '' }`"
              >
                <div>
                  <h3 class="font-weight-bolder mb-0">
                    {{ formatCurrencyUSD(resumo.aguardandoLiberacao, 6) }}

                  </h3>
                  <p class="mb-0 text-secondary">
                    {{ $t('indicacao.extrato.textAguardLiberacao') }}
                  </p>
                </div>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            cols="6"
            md="2"
            class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside class="mt-1">
                <b-avatar
                  size="48"
                  variant="light-success"
                >
                  <feather-icon
                    size="24"
                    icon="DollarSignIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body
                :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start ml-1' : '' }`"
              >
                <div>
                  <h3 class="font-weight-bolder mb-0">
                    {{ formatCurrencyUSD(resumo.disponivel, 6) }}
                  </h3>
                  <p class="mb-0 text-secondary">
                    {{ $t('indicacao.extrato.textDisponivel') }}
                  </p>
                </div>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            cols="6"
            md="2"
            class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside class="mt-1">
                <b-avatar
                  size="48"
                  variant="light-warning"
                >
                  <feather-icon
                    size="24"
                    icon="BellIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body
                :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start ml-1' : '' }`"
              >
                <div>
                  <h3 class="font-weight-bolder mb-0">
                    {{ formatCurrencyUSD(resumo.aguardandoPagamento, 6) }}
                  </h3>
                  <p class="mb-0 text-secondary">
                    {{ $t('indicacao.extrato.textAguardPagamento') }}
                  </p>
                </div>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            cols="6"
            md="2"
            class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside class="mt-1">
                <b-avatar
                  size="48"
                  variant="light-success"
                >
                  <feather-icon
                    size="24"
                    icon="DollarSignIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body
                :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start ml-1' : '' }`"
              >
                <div>
                  <h3 class="font-weight-bolder mb-0">
                    {{ formatCurrencyUSD(resumo.pago, 6) }}
                  </h3>
                  <p class="mb-0 text-secondary">
                    {{ $t('indicacao.extrato.textPago') }}
                  </p>
                </div>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
    >
      <b-table
        ref="refListTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('indicacao.emptyTextTable')"
        :sort-desc.sync="isSortDirDesc"
        striped
      >
        <template #cell(dataCriacao)="data">
          {{ formatDateBR(data.value) }}
        </template>
        <template #cell(tipo)="data">
          {{ $t(data.value) }} {{ data.item.produto }}
        </template>
        <template #cell(descricao)="data">
          <template v-if="data.item.tipo=='TAXA_DESEMPENHO' || data.item.tipo=='TAXA_DESEMPENHO_TRADER'">
            <span style="white-space: nowrap;">{{ data.item.agente }} </span><br>
            <b-badge variant="light-primary">
              #{{ data.item.sinalId }}
            </b-badge>
          </template>
          <template v-else>
            Plan {{ data.item.plano }}
          </template>
        </template>
        <template #cell(dataLiberacao)="data">
          {{ formatDateTimeBR(data.value) }}
        </template>
        <template #cell(dataSaque)="data">
          {{ formatDateBR(data.value) }}
        </template>
        <template #cell(dataPagamento)="data">
          {{ formatDateBR(data.value) }}
        </template>
        <template #cell(nivel)="data">
          {{ data.value }} <small>({{ data.item.porcentagem }}%)</small>
        </template>
        <template #cell(valor)="data">
          {{ formatCurrencyUSD(data.value, 6) }}
        </template>
        <template #cell(valorComissao)="data">
          {{ formatCurrencyUSD(data.value, 6) }}
        </template>
        <template #cell(status)="data">
          <b-badge :variant="getStatusColor(data.value)">
            {{ data.value }}
          </b-badge>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('indicacao.extrato.alertTextMuted', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BPagination, BRow, BCol, BBadge, BCardBody, BMedia, BMediaAside, BMediaBody, BAvatar, BCardHeader, BCardTitle,

} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import { formatDateTimeBR, formatDateBR, formatCurrencyUSD } from '@/utils/filter'
import extratoStoreModule from './extratoStoreModule'

const INDICACAO_EXTRATO_STORE_MODULE_NAME = 'indicacao-extrato'

export default {

  components: {
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BRow,
    BCol,
    BBadge,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BCardHeader,
    BCardTitle,
  },

  mixins: [utilsMixin],

  data() {
    return {
      formatCurrencyUSD,
      formatDateBR,
      formatDateTimeBR,
      loadingOverlayGrid: false,
      perPage: 10,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'dataCriacao,desc',
      isSortDirDesc: true,
      dataList: null,
      resumo: null,
    }
  },

  computed: {
    tableColumns() {
      return [
        { key: 'dataCriacao', label: this.$i18n.t('indicacao.dataCriacao') },
        { key: 'indicadoEmail', label: this.$i18n.t('indicacao.extrato.indicadoEmail') },
        { key: 'tipo', label: this.$i18n.t('indicacao.extrato.tipo') },
        { key: 'valor', label: this.$i18n.t('indicacao.extrato.valorComissao') },
        { key: 'nivel', label: this.$i18n.t('indicacao.extrato.nivel') },
        { key: 'valorComissao', label: this.$i18n.t('indicacao.extrato.lucro') },
        { key: 'status', label: this.$i18n.t('indicacao.status') },
        { key: 'descricao', label: this.$i18n.t('indicacao.extrato.descricao') },
        { key: 'dataLiberacao', label: this.$i18n.t('indicacao.extrato.dataLiberacao') },
        { key: 'dataSaque', label: this.$i18n.t('indicacao.extrato.dataSaque') },
        { key: 'dataPagamento', label: this.$i18n.t('indicacao.extrato.dataPagamento') },
      ]
    },
    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },
    windowWidth() {
      return this.$store.state.app.windowWidth
    },
  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(INDICACAO_EXTRATO_STORE_MODULE_NAME)) store.registerModule(INDICACAO_EXTRATO_STORE_MODULE_NAME, extratoStoreModule)
    this.fetchList()
    this.getExtratoResumo()
  },

  beforeDestroy() {
    if (store.hasModule(INDICACAO_EXTRATO_STORE_MODULE_NAME)) store.unregisterModule(INDICACAO_EXTRATO_STORE_MODULE_NAME)
  },

  methods: {

    getStatusColor(value) {
      if (value === 'Aguardando liberação') return 'primary'
      if (value === 'Disponível para saque') return 'success'
      if (value === 'Aguardando pagamento') return 'warning'
      if (value === 'Pago') return 'success'
      if (value === 'Cancelado') return 'danger'
      if (value === 'Estornado') return 'danger'
      return 'warning'
    },

    getExtratoResumo() {
      store
        .dispatch('indicacao-extrato/getExtratoResumo', { })
        .then(response => {
          this.resumo = response.data
        })
    },

    fetchList() {
      store
        .dispatch('indicacao-extrato/getExtrato', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
        })
    },
  },
}
</script>
