import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getExtrato(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('financeiro-indicacao/extrato', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getExtratoResumo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('financeiro-indicacao/extrato-resumo', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },
  },
}
